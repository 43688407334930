import React from 'react'
import { translate } from 'locales'
// Styles
import { Title } from 'components'
import useStorage from 'hooks/storageSync'
import * as S from './styles'

// Hooks

const Resume: React.FunctionComponent<{
  doctorName: string
  scheduleId: string
  medicalRecord: any
  symptoms: string
}> = ({ doctorName, scheduleId, medicalRecord, symptoms }) => {
  const [storage]: any = useStorage(scheduleId, 'complementarExams')

  // Recupera os valores salvos no storage para a consulta atual na sessão Conduct
  const analysis = storage?.analysis

  const medicalAllergy = medicalRecord?.medical_allergy_description
  const diabetic = medicalRecord?.diabetic
  const pathologicalAntecedents = medicalRecord?.Medical_record_heath_problem
  const continuousRemedy = medicalRecord?.continuous_remedy

  return (
    <S.SectionContent>
      <span>{translate('medicalRecord.resume')}</span>
      <p>{`${translate('medicalRecord.by')} ${doctorName}`}</p>

      <S.Div>
        <span>{translate('medicalRecord.reason')}</span>
        <h5 style={{ marginTop: '10px', fontSize: '14px' }}>
          {!symptoms ? translate('medicalRecord.uninformed') : symptoms}
        </h5>
      </S.Div>
      <S.Div className="subsection-item">
        <span>{translate('medicalRecord.allergies')}</span>
        <p>
          {!medicalAllergy
            ? translate('medicalRecord.uninformed')
            : medicalAllergy}
        </p>
      </S.Div>
      <S.Div className="subsection-item">
        <span>{translate('medicalRecord.diabetes')}</span>
        <p>
          {diabetic === true
            ? translate('general.yes')
            : translate('general.no')}
        </p>
      </S.Div>
      <S.Div className="subsection-item">
        <span>{translate('medicalRecord.pathologicalBackground')}</span>

        {pathologicalAntecedents?.length > 0 ? (
          pathologicalAntecedents?.map((item: any) => {
            return (
              <p key={item.name}>
                {!item.name ? translate('medicalRecord.uninformed') : item.name}
              </p>
            )
          })
        ) : (
          <p>{translate('medicalRecord.uninformed')}</p>
        )}
      </S.Div>
      <S.Div className="subsection-item">
        <span>{translate('medicalRecord.continuousRemedy')}</span>
        <p>
          {!continuousRemedy
            ? translate('medicalRecord.uninformed')
            : continuousRemedy}
        </p>
      </S.Div>
      <S.Div className="subsection">
        <Title text={translate('medicalRecord.analysis')} />
        <div className="subsection-item">
          <p>{analysis?.analysis}</p>
        </div>
      </S.Div>
    </S.SectionContent>
  )
}

export default Resume
