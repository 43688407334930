import React from 'react'
import moment from 'moment'

import { translate } from 'locales'

// Styles
import useStorage from 'hooks/storageSync'
import logo from 'assets/images/logo.svg'

import { TextArea } from 'components'
import * as S from './styles'

// Interfaces
import { IComponentProps } from './interfaces'

// Components
import TagSelector from './TagSelector/TagSelector'

const Analysis: React.FunctionComponent<IComponentProps> = ({
  doctorName,
  scheduleUUID,
  scheduleData,
}) => {
  const [storage, setStorage]: any = useStorage(scheduleUUID, 'analysis')

  // Recupera os valores salvos no storage para a consulta atual na sessão Conduct
  const dataStorage: any = storage?.analysis

  const handleChange = (key: string, e: string) => {
    setStorage(key, e)
  }

  const count = dataStorage?.analysis ? dataStorage?.analysis.length : 0

  const psyco = scheduleData?.specialty_slug === 'psicologo'

  return (
    <S.SectionContent>
      <span>{moment(new Date()).format('dddd, DD/MM/YYYY')}</span>
      <p>{`${translate('medicalRecord.by')} ${doctorName}`}</p>
      <S.Div>
        <span>{translate('medicalRecord.reason')}</span>
        <h5 style={{ marginTop: '10px', fontSize: '14px' }}>
          {!scheduleData?.symptoms
            ? translate('medicalRecord.uninformed')
            : scheduleData?.symptoms}
        </h5>
        {psyco && <TagSelector scheduleUUID={scheduleUUID} />}
      </S.Div>
      <div className="subsection">
        <div className="subsection-item">
          <S.AnalysisWrapper>
            <div className="analysisHeader">
              <img src={logo} alt="Missão Saude" />
            </div>
            <TextArea
              styles={{ border: 'none' }}
              name="analysis"
              value={dataStorage?.analysis}
              placeholder={translate('medicalRecord.describe')}
              onChange={(e: any) => handleChange('analysis', e.target.value)}
              rows={30}
              wrap="hard"
            />
            <div className="analysisFooter">
              <a href="https://www.starbem.app">www.starbem.app</a>
            </div>
          </S.AnalysisWrapper>
        </div>
      </div>
      <S.CountCharacters>{`Caracteres: ${count} / 20000`}</S.CountCharacters>
    </S.SectionContent>
  )
}

export default Analysis
