import React from 'react'
import moment from 'moment'

import { translate } from 'locales'
import { TextArea } from 'components'
import Form from 'react-bootstrap/Form'
import useStorage from 'hooks/storageSync'
import TagSelectorNutri from './TagSelectorNutri/TagSelectorNutri'

// Interfaces
import { IComponentProps } from './interfaces'

// utils
import {
  reasonConsultation,
  pathologies,
  waterConsumption,
  intestinalHabit,
  fraquency,
  sleep,
  youAre,
} from './utils'

import * as S from './styles'

const updatedReasonConsultation = reasonConsultation.filter(
  (reason) => reason !== 'Retorno'
)
const firstReasonColumn = [
  ...updatedReasonConsultation.slice(
    0,
    Math.ceil(updatedReasonConsultation.length / 2) - 1
  ),
  'Retorno',
]
const secondReasonColumn = updatedReasonConsultation.slice(
  Math.ceil(updatedReasonConsultation.length / 2) - 1
)

const updatedPathologies = pathologies.filter(
  (pathology) => pathology !== 'Nenhuma'
)
const firstPathologyColumn = [
  ...updatedPathologies.slice(0, Math.ceil(updatedPathologies.length / 2) - 1),
  'Nenhuma',
]
const secondPathologyColumn = updatedPathologies.slice(
  Math.ceil(updatedPathologies.length / 2) - 1
)

const updatedFraquency = [...fraquency, 'Diariamente']
const updatedSleep = [...sleep, 'Dorme bem']

const AnamneseNutri: React.FunctionComponent<IComponentProps> = ({
  doctorName,
  scheduleUUID,
}) => {
  const [storage, setStorage, setAllStorage]: any = useStorage(
    scheduleUUID,
    'nutritional_anamnese'
  )

  const handleChange = (nome: any, valor: any) => {
    const newItens = storage?.nutritional_anamnese?.[nome] || []

    if (newItens.includes(valor)) {
      const filtered = newItens?.filter((item: any) => item !== valor)
      const data = {
        ...storage,
        nutritional_anamnese: {
          ...storage?.nutritional_anamnese,
          [nome]: filtered,
        },
      }
      setAllStorage(data)
    } else {
      const data = {
        ...storage,
        nutritional_anamnese: {
          ...storage?.nutritional_anamnese,
          [nome]: [...newItens, valor],
        },
      }
      setAllStorage(data)
    }
  }

  const handleWeightChange = (key: string, e: any) => {
    let { value } = e.target

    value = value.replace(/[^\d.]/g, '')

    const match = value.match(/^\d{0,3}(\.\d{0,2})?$/)
    if (match) {
      setStorage(key, value)
    }
  }

  const handleChangeText = (key: any, e: any) => {
    setStorage(key, e.target.value)
  }

  const handleChangeCheckBox = (e: any, value: any) => {
    setStorage(e, value)
  }

  return (
    <S.SectionContent>
      <span>{moment(new Date()).format('dddd, DD/MM/YYYY')}</span>
      <p>{`${translate('medicalRecord.by')} ${doctorName}`}</p>
      <S.Div>
        <h3>Peso:</h3>
        <div style={{ display: 'flex', gap: '20px' }}>
          <div className="subsection-item" style={{ flex: 1 }}>
            <h4>Peso antigo:</h4>
            <div style={{ position: 'relative' }}>
              <Form.Control
                type="text"
                name="old_weight"
                value={storage?.nutritional_anamnese?.old_weight || ''}
                placeholder="Digite o peso antigo"
                onChange={(e: any) => handleWeightChange('old_weight', e)}
                style={{
                  borderRadius: '5px',
                  padding: '10px',
                  border: '1px solid #ccc',
                }}
              />
              <span
                style={{
                  position: 'absolute',
                  right: '30px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                kg
              </span>
            </div>
          </div>
          <div className="subsection-item" style={{ flex: 1 }}>
            <h4>Peso atual:</h4>
            <div style={{ position: 'relative' }}>
              <Form.Control
                type="text"
                name="current_weight"
                value={storage?.nutritional_anamnese?.current_weight || ''}
                placeholder="Digite o peso atual"
                onChange={(e: any) => handleWeightChange('current_weight', e)}
                style={{
                  borderRadius: '5px',
                  padding: '10px',
                  border: '1px solid #ccc',
                }}
              />
              <span
                style={{
                  position: 'absolute',
                  right: '30px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                kg
              </span>
            </div>
          </div>
        </div>

        <h3 style={{ marginBottom: '10px', marginTop: '20px' }}>
          {translate('medicalRecord.reason')}
        </h3>
        <Form style={{ marginTop: '10px' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <div style={{ flex: '1 1 calc(50% - 10px)' }}>
              {firstReasonColumn.map((reason) => (
                <div key={reason} className="mb-1">
                  <Form.Check
                    inline
                    label={reason}
                    name="reason"
                    type="checkbox"
                    id={reason}
                    checked={storage?.nutritional_anamnese?.reason?.includes(
                      reason
                    )}
                    onChange={(e) => handleChange(e.target.name, reason)}
                  />
                </div>
              ))}
            </div>
            <div style={{ flex: '1 1 calc(50% - 10px)' }}>
              {secondReasonColumn.map((reason) => (
                <div key={reason} className="mb-1">
                  <Form.Check
                    inline
                    label={reason}
                    name="reason"
                    type="checkbox"
                    id={reason}
                    checked={storage?.nutritional_anamnese?.reason?.includes(
                      reason
                    )}
                    onChange={(e) => handleChange(e.target.name, reason)}
                  />
                </div>
              ))}
            </div>
          </div>
        </Form>
        <h4 style={{ marginBottom: '5px', marginTop: '20px' }}>
          {translate('nutritionalReason.comments')}:
        </h4>
        <TextArea
          name="reason_comments"
          value={storage?.nutritional_anamnese?.reason_comments}
          placeholder={translate('medicalRecord.describe')}
          onChange={(e: any) => handleChangeText('reason_comments', e)}
          wrap="hard"
        />

        <h3>{translate('medicalRecord.pathologies')}</h3>
        <Form style={{ marginTop: '10px' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <div style={{ flex: '1 1 calc(50% - 10px)' }}>
              {firstPathologyColumn.map((patologia) => (
                <div key={patologia} className="mb-1">
                  <Form.Check
                    inline
                    label={patologia}
                    name="pathologies"
                    type="checkbox"
                    id={patologia}
                    checked={storage?.nutritional_anamnese?.pathologies?.includes(
                      patologia
                    )}
                    onChange={(e) => handleChange(e.target.name, patologia)}
                  />
                </div>
              ))}
            </div>
            <div style={{ flex: '1 1 calc(50% - 10px)' }}>
              {secondPathologyColumn.map((patologia) => (
                <div key={patologia} className="mb-1">
                  <Form.Check
                    inline
                    label={patologia}
                    name="pathologies"
                    type="checkbox"
                    id={patologia}
                    checked={storage?.nutritional_anamnese?.pathologies?.includes(
                      patologia
                    )}
                    onChange={(e) => handleChange(e.target.name, patologia)}
                  />
                </div>
              ))}
            </div>
          </div>
        </Form>
        <h4 style={{ marginBottom: '5px', marginTop: '20px' }}>
          {translate('nutritionalReason.comments')}:
        </h4>
        <TextArea
          name="pathologies_comments"
          value={storage?.nutritional_anamnese?.pathologies_comments}
          placeholder={translate('medicalRecord.describe')}
          onChange={(e: any) => handleChangeText('pathologies_comments', e)}
          wrap="hard"
        />
        <h3>{translate('nutritionalReason.medicines')}</h3>
        <Form style={{ marginTop: '10px', marginBottom: '10px' }}>
          <Form.Check
            inline
            label="Sim"
            name="medicines"
            type="radio"
            checked={storage?.nutritional_anamnese?.medicines === true}
            onChange={() => handleChangeCheckBox('medicines', true)}
          />
          <Form.Check
            inline
            label="Não"
            name="medicines"
            type="radio"
            checked={storage?.nutritional_anamnese?.medicines === false}
            onChange={() => handleChangeCheckBox('medicines', false)}
          />
        </Form>
        <h4>{translate('nutritionalReason.which')}:</h4>
        <TextArea
          name="medicines_comments"
          value={storage?.nutritional_anamnese?.medicines_comments}
          placeholder={translate('medicalRecord.describe')}
          onChange={(e: any) => handleChangeText('medicines_comments', e)}
          wrap="hard"
        />
        <h3>{translate('nutritionalReason.supplements')}:</h3>
        <TagSelectorNutri scheduleUUID={scheduleUUID} />
        <h3>{translate('nutritionalReason.praticePhysicalActivity')}:</h3>
        <Form style={{ marginTop: '10px', marginBottom: '10px' }}>
          <Form.Check
            inline
            label="Sim"
            name="practice_activity"
            type="radio"
            checked={storage?.nutritional_anamnese?.practice_activity === true}
            onChange={() => handleChangeCheckBox('practice_activity', true)}
          />
          <Form.Check
            inline
            label="Não"
            name="practice_activity"
            type="radio"
            checked={storage?.nutritional_anamnese?.practice_activity === false}
            onChange={() => handleChangeCheckBox('practice_activity', false)}
          />
        </Form>
        <h4>{translate('nutritionalReason.frequencyAndSchedule')}</h4>
        <TextArea
          name="practice_activity_comments"
          value={storage?.nutritional_anamnese?.practice_activity_comments}
          placeholder={translate('medicalRecord.describe')}
          onChange={(e: any) =>
            handleChangeText('practice_activity_comments', e)
          }
          wrap="hard"
        />
        <h3>{translate('nutritionalReason.waterConsumption')}:</h3>
        <Form style={{ marginTop: '10px' }}>
          <div className="mb-3">
            {waterConsumption?.map((item) => (
              <Form.Check
                key={`inline-${item}`}
                inline
                label={item}
                name="water_consumption"
                type="radio"
                id={`inline-${item}`}
                checked={
                  storage?.nutritional_anamnese?.water_consumption === item
                }
                onChange={() => handleChangeCheckBox('water_consumption', item)}
              />
            ))}
          </div>
        </Form>
        <h4>{translate('nutritionalReason.comments')}:</h4>
        <TextArea
          name="water_consumption_comments"
          value={storage?.nutritional_anamnese?.water_consumption_comments}
          placeholder={translate('medicalRecord.describe')}
          onChange={(e: any) =>
            handleChangeText('water_consumption_comments', e)
          }
          wrap="hard"
        />
        <h3>{translate('nutritionalReason.intestinalHabit')}</h3>
        <h4>{translate('nutritionalReason.frequency')}:</h4>
        <Form style={{ marginTop: '10px' }}>
          <div className="mb-3">
            {updatedFraquency.map((item) => (
              <Form.Check
                key={`inline-${item}`}
                inline
                label={item}
                name="frequency_intestinal_habit"
                type="radio"
                id={`inline-${item}`}
                checked={
                  storage?.nutritional_anamnese?.frequency_intestinal_habit ===
                  item
                }
                onChange={() =>
                  handleChangeCheckBox('frequency_intestinal_habit', item)
                }
              />
            ))}
          </div>
        </Form>
        <h4>{translate('nutritionalReason.consistency')}</h4>
        <Form style={{ marginTop: '10px' }}>
          <div className="mb-3">
            {intestinalHabit?.map((item) => (
              <Form.Check
                key={`inline-${item}`}
                inline
                label={item}
                name="consistency_intestinal_habit"
                type="radio"
                id={`inline-${item}`}
                checked={
                  storage?.nutritional_anamnese
                    ?.consistency_intestinal_habit === item
                }
                onChange={() =>
                  handleChangeCheckBox('consistency_intestinal_habit', item)
                }
              />
            ))}
          </div>
        </Form>
        <h4>{translate('nutritionalReason.comments')}:</h4>
        <TextArea
          name="intestinal_habit_comments"
          value={storage?.nutritional_anamnese?.intestinal_habit_comments}
          placeholder={translate('medicalRecord.describe')}
          onChange={(e: any) =>
            handleChangeText('intestinal_habit_comments', e)
          }
          wrap="hard"
        />
        <h3>{translate('nutritionalReason.sleep')}</h3>
        <Form style={{ marginTop: '10px' }}>
          <div className="mb-3">
            {updatedSleep.map((item) => (
              <Form.Check
                key={`inline-${item}`}
                inline
                label={item}
                name="sleep"
                type="checkbox"
                id={`inline-${item}`}
                checked={storage?.nutritional_anamnese?.sleep?.includes(item)}
                onChange={(e) => handleChange(e.target.name, item)}
              />
            ))}
          </div>
        </Form>
        <h4>{translate('nutritionalReason.comments')}:</h4>
        <TextArea
          name="sleep_comments"
          value={storage?.nutritional_anamnese?.sleep_comments}
          placeholder={translate('medicalRecord.describe')}
          onChange={(e: any) => handleChangeText('sleep_comments', e)}
          wrap="hard"
        />
        <h4>{translate('nutritionalReason.foodAllergiesOrIntolerance')}</h4>
        <Form style={{ marginTop: '10px', marginBottom: '10px' }}>
          <Form.Check
            inline
            label="Sim"
            name="intolerances"
            type="radio"
            checked={storage?.nutritional_anamnese?.intolerances === 'Sim'}
            onChange={() => handleChangeCheckBox('intolerances', 'Sim')}
          />
          <Form.Check
            inline
            label="Não"
            name="intolerances"
            type="radio"
            checked={storage?.nutritional_anamnese?.intolerances === 'Não'}
            onChange={() => handleChangeCheckBox('intolerances', 'Não')}
          />
        </Form>
        <h4>{translate('nutritionalReason.which')}:</h4>
        <TextArea
          name="intolerances_comments"
          value={storage?.nutritional_anamnese?.intolerances_comments}
          placeholder={translate('medicalRecord.describe')}
          onChange={(e: any) => handleChangeText('intolerances_comments', e)}
          wrap="hard"
        />
        <h3>{translate('nutritionalReason.youAre')}</h3>
        <Form style={{ marginTop: '10px' }}>
          <div className="mb-3">
            {youAre?.map((item) => (
              <Form.Check
                key={`inline-${item}`}
                inline
                label={item}
                name="diet"
                type="radio"
                id={`inline-${item}`}
                checked={storage?.nutritional_anamnese?.diet === item}
                onChange={() => handleChangeCheckBox('diet', item)}
              />
            ))}
          </div>
        </Form>
        <h4>{translate('nutritionalReason.comments')}:</h4>
        <TextArea
          name="diet_comments"
          value={storage?.nutritional_anamnese?.diet_comments}
          placeholder={translate('medicalRecord.describe')}
          onChange={(e: any) => handleChangeText('diet_comments', e)}
          wrap="hard"
        />
        <h3>{translate('nutritionalReason.foodRoutine')}</h3>
        <h4>{translate('nutritionalReason.breakfast')}:</h4>
        <TextArea
          name="routine_breakfast"
          value={storage?.nutritional_anamnese?.routine_breakfast}
          placeholder={translate('medicalRecord.describe')}
          onChange={(e: any) => handleChangeText('routine_breakfast', e)}
          wrap="hard"
        />
        <h4>{translate('nutritionalReason.morningSnack')}:</h4>
        <TextArea
          name="routine_morning_snack"
          value={storage?.nutritional_anamnese?.routine_morning_snack}
          placeholder={translate('medicalRecord.describe')}
          onChange={(e: any) => handleChangeText('routine_morning_snack', e)}
          wrap="hard"
        />
        <h4>{translate('nutritionalReason.lunch')}:</h4>
        <TextArea
          name="routine_lunch"
          value={storage?.nutritional_anamnese?.routine_lunch}
          placeholder={translate('medicalRecord.describe')}
          onChange={(e: any) => handleChangeText('routine_lunch', e)}
          wrap="hard"
        />
        <h4>{translate('nutritionalReason.afternoonSnack')}:</h4>
        <TextArea
          name="routine_afternoon_snack"
          value={storage?.nutritional_anamnese?.routine_afternoon_snack}
          placeholder={translate('medicalRecord.describe')}
          onChange={(e: any) => handleChangeText('routine_afternoon_snack', e)}
          wrap="hard"
        />
        <h4>{translate('nutritionalReason.dinner')}:</h4>
        <TextArea
          name="routine_dinner"
          value={storage?.nutritional_anamnese?.routine_dinner}
          placeholder={translate('medicalRecord.describe')}
          onChange={(e: any) => handleChangeText('routine_dinner', e)}
          wrap="hard"
        />
        <h4>{translate('nutritionalReason.supper')}:</h4>
        <TextArea
          name="routine_supper"
          value={storage?.nutritional_anamnese?.routine_supper}
          placeholder={translate('medicalRecord.describe')}
          onChange={(e: any) => handleChangeText('routine_supper', e)}
          wrap="hard"
        />
        <h4>{translate('nutritionalReason.weekends')}:</h4>
        <TextArea
          name="routine_weekends"
          value={storage?.nutritional_anamnese?.routine_weekends}
          placeholder={translate('medicalRecord.describe')}
          onChange={(e: any) => handleChangeText('routine_weekends', e)}
          wrap="hard"
        />
        <h4>{translate('nutritionalReason.anyFoodAversions')}</h4>
        <TextArea
          name="food_aversion"
          value={storage?.nutritional_anamnese?.food_aversion}
          placeholder={translate('medicalRecord.describe')}
          onChange={(e: any) => handleChangeText('food_aversion', e)}
          wrap="hard"
        />
        <h4>{translate('nutritionalReason.anyConsiderations')}</h4>
        <TextArea
          name="considerations"
          value={storage?.nutritional_anamnese?.considerations}
          placeholder={translate('medicalRecord.describe')}
          onChange={(e: any) => handleChangeText('considerations', e)}
          wrap="hard"
        />
        <h4>Informações extras:</h4>
        <TextArea
          name="extra_information"
          value={storage?.nutritional_anamnese?.extra_information}
          placeholder="Descreva aqui"
          onChange={(e: any) => handleChangeText('extra_information', e)}
          wrap="hard"
        />
      </S.Div>
    </S.SectionContent>
  )
}

export default AnamneseNutri
